import './css/main.css';

import $ from "expose-loader?exposes=$,jQuery!jquery";

import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);



barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
        {
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    opacity: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
                loadMap();
                loadAnimations();
            }
        },
    ],
});



function loadMap() {
    let map = document.getElementById('daMap');
    if (map != undefined && map != null) {
        import(/* webpackChunkName: "map" */ "./js/components/map.js").then(module => {
            const mapGo = module.default;
            mapGo();
        });
    }
};


function loadAnimations() {
    import(/* webpackChunkName: "animations" */ "./js/components/animations.js").then(module => {
        const animGo = module.default;
        animGo();
    });
}

  function scrollToTop() {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    window.scrollTo({top: 0});
  };


function hamburgerToggle(preset) {
    import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
        const toggle = module.default;
        toggle(preset);
    });
}

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});

let menuCloser = document.getElementById('dropdownCloser');
menuCloser.addEventListener("click", () => {hamburgerToggle('c')});


barba.hooks.once((data) => {

});

barba.hooks.afterOnce((data) => {

});

barba.hooks.beforeEnter((data) => {
    scrollToTop();
    hamburgerToggle('c');
});

barba.hooks.before((data) => {
    hamburgerToggle('c');
});


barba.hooks.after((data) => {
    loadMap();
    loadAnimations();
    
});




//   document.querySelector('body').classList.remove('no-js');